@import "../../styles/mixins/global.scss";
.courses-section {
    // padding: 101px 0;
    padding: 64px 0;
    width: 95vw;
   
    .page-title {
        padding: 0 0 23px 0;
        display: flex;
        justify-content: space-between;
        h1 {
            @include heading-3;
            color: var(--colors-black-1);
            margin: 0 0 31px 0;
        }
        p {
            max-width: 928px;
            @include text-sm;
            line-height: 20px;
            @include font-medium;
            color: var(--colors-black-3);
        }
        .btns {
            padding: 6px 17px;
            background-color: var(--colors-orange);
            cursor: pointer;
            color: var(--colors-white);
            box-shadow: 0px 4px 4px rgb(0 0 0 / 16%);
            border: none;
            font-size: 19px;
            line-height: 30px;
            font-family: var(--default-font-family);
            font-weight: var(--font-medium);
            border-radius: 999px;
            margin-bottom: 17px;
        }
    }
    .grids {
        @include layout-grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 40px;
        .grid-itemss {
            border-radius: 10px;
            background-color: #fff;
            padding: 35px;
            @media (max-width: 767px){
                padding: 0;
            }
            // .spce {
            //     display: none;
            //     align-items: n;
            //     justify-content: space-between;
            //     margin-bottom: 0 !important;
            //     font-size: large;
            .flex-display {
                display: flex;
                justify-content: space-between;
                margin-bottom: 0 !important;
                font-size: large;
                button {
                    padding: 10px 30px;
                    background-color: var(--colors-orange);
                    @include pointer;
                    color: var(--colors-white);
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
                    border: none;
                    @include text-md;
                    line-height: 20px;
                    @include font-medium;
                    border-radius: 999px;
                }
                h2 {
                    @include heading-3;
                    font-size: 18px;
                    line-height: 36px;
                    color: var(--colors-black-1);
                    margin-bottom: 0px;
                    padding-bottom: 0;
                }
                p {
                    @include text-xs;
                    font-size: 15px;
                    padding: 8px 0 0 0;
                    line-height: 18px;
                    margin: 0 0 15px 0;
                    @include font-medium;
                }
            }
        }
    }
}

// }
.image-icon-center-alignment {
    display: flex;
    align-content: center;
    padding: 5px 0 0 0;
    div+div {
        padding-left: 10px;
    }
    div {
        display: flex;
        align-items: center;
    }
    span {
        @include text-sm;
        color: #323232;
    }
    img {
        max-width: 16px;
    }
}