    @import '../../styles/mixins/global.scss';
    .profile-page-section-alignment {
        padding: 120px 0;
        .grid {
            display: grid;
            grid-template-columns: 400px minmax(0, 1fr);
            gap: 0 100px;
            .grid-items {
                padding-left: 1%;
                padding-right: 1%;
                @media(max-width: 767px) {
                    width: 91.5vw;
                }
                .input {
                    margin: 0 0 16px 0;
                }
                .profile-image-alignment {
                    display: flex;
                    margin: 0 0 45px 0;
                    div:last-child {
                        display: flex;
                        align-items: flex-end;
                        margin-left: 6px;
                        label {
                            background: #5B5B5E;
                            @include pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 40px;
                            height: 40px;
                            border-radius: 10px;
                            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
                        }
                    }
                    div+div {}
                    div {
                        img {
                            width: 203px;
                            height: 203px;
                            border-radius: 10px;
                        }
                    }
                }
            }
        }
    }
    
    .profile-image-upload {
        max-width: 180px;
        margin: 0 auto;
        position: relative;
        margin-bottom: 30px;
        .profile-image-size {
            width: 180px;
            background-color: #C4C4C4;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 180px;
            .upload-input-align {
                width: 30px;
                height: 30px;
                border-radius: 50%;
                position: absolute;
                cursor: pointer;
                right: 6px;
                bottom: 20px;
                color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                input {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    opacity: 0;
                }
            }
            img {
                width: 180px;
                height: 180px;
                // border-radius: 50%;
                object-fit: cover;
            }
            .fa-solid {
                width: 30px;
            }
        }
    }
    
    .hide {
        display: none;
    }
    
    .update-btn {
        padding: 10px 30px;
        background-color: #5B5B5E;
        color: white;
        border-radius: 999px;
        border: none;
        font-size: 18px; 
        &:hover{
            background-color: white;
  color: #5B5B5E;
        }
    }