@forward "./breakpoint";
@forward './columns';
@forward  './typography';


.layout-grid {
    display: grid;
    grid-template-columns: 210px minmax(0, 1fr);
    gap: 54px;
    .layout-grid-items:nth-child(2) {
        padding-right: 54px;

        @media(max-width: 1024px){
            padding-right: 47px;
        }

        @media(max-width: 767px){
            padding-right: 20px;
            margin-top: 35px;
        }

        @media(max-width: 320px){
            padding-right: 20px;
        }
    }

    @media(max-width: 1024px){
        gap: 35px;
        grid-template-columns: 206px minmax(0 , 1fr);
    }

    @media(max-width: 767px){
        gap: 20px;
        grid-template-columns: 0px minmax(0 , 1fr);

      
    }
}