:root {
    --default-font-family:'Asap', sans-serif;


    --font-normal:400;
    --font-medium:500;
    --font-bold:600;
    --font-extra-bold:800;

    --colors-white:#fff;
    --colors-black:#000000;
    --colors-gray:#e7d1a6;
    --colors-orange:#DFD1B6;
    --colors-black-1: #010B1D;
    --colors-black-3:#3d3d3d;
    
    --bg-light:#F9F9F9;
    --bg-light-dark:#E1E1E1;
    --bg-orange-light: rgba(242, 145, 0, 0.1);
    --s1:0px 30px 30px 0px;
    --s2: 0px 4px 4px rgba(0, 0, 0, 0.16);

}