@import "../../styles/mixins/global.scss";
.sidebar-sticky {
  position: fixed;
  top: 0;
  width: 210px;
}

.sidebar {
  background-color: var(--bg-light);
  height: 100vh;
  border-radius: 0px 30px 30px 0px;
  z-index: 99;
  .sidebar_scroll {
    padding-right: 30px;
  }
  .sidebar-alignment {
    height: 100%;
    padding: 10px 0px 0 17px;

    .sub-menu-alignment {
      .sub-menu {
        margin: 0 0 16px 0;
        padding: 6px 15px;
        border-radius: 10px;
        @include flex-center;
        @include pointer;
        transition: 0.3s ease-in-out;
        &:hover {
          span{
            color: #f9f9f9;
          }
          // background-color: var(--colors-gray);
          background-color:  #e7d1a6;
        }
        div:first-child {
          width: 35px;
          min-width: 35px;
          margin-right: map-get($pixel, md) * 2;
          height: 35px;
          @include flex-center;
          @include pointer;
          background-color: var(--colors-orange);
          justify-content: center;
          border-radius: 10px;
          box-shadow: var(--s2);
        }
        div {
          span {
            font-size: 16px;
            font-weight: 500;
            color: var(--colors-black);
            display: block;
            @media (max-width: 1024px) {
              font-size: 14px;
            }
          }
        }
      }
      .active {
        background-color: var(--colors-gray);
        border-radius: 10px;
        .home1{
          color: #f9f9f9;
        }
      }
    }
    .profile-show {
      background-color: var(--bg-light-dark);
      border-radius: 15px;
      margin: -12px 24px 27px 0;
      padding: 10px 16px;
      cursor: pointer;
      .profile-details-alignment {
        cursor: pointer;
        @include flex-between;
        .all-profile-info {
          @include flex-center;
        }
        .user-details {
          p {
            @include text-xs;
            color: var(--colors-black);
          }
          span {
            @include text-3xs;
            color: var(--colors-black);
            display: block;
          }
        }
        .profile-image {
          padding-right: map-get($pixel, sm) * 2 - 2px;
          img {
            width: 40px;
            height: 40px;
            border-radius: 5px;
            object-fit: cover;
          }
        }
      }
    }
    @media (max-width: 1024px) {
      padding: 56px 32px 0 17px;
    }
  }

  @media (max-width: 1024px) {
    max-width: 207px;
  }
  @media (max-width: 767px) {
    display: none;
  }
}

.mainLogo {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  // img {
  //     width: 140px;
  // }
  @media (max-width: 1024px) {
    padding-bottom: 43px;
  }
  .firstlogo {
    padding: 10px;
  }
  img {
    height: 75%;
    width: 86%;
  }
}

.mainLogo1 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.img-logo {
  height: 60px;
  width: 60px;
  @media (max-width: 1024px) {
    width: 41.89px;
    height: 45.56px;
  }
}

.footer-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  bottom: 0;
  position: absolute;
  .footer-logo {
    height: 80px;
    width: 200px;
  }
}

.header {
  background-color: #f9f9f9;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  padding: 20px 30px 20px 27px;
  width: 100vw;
  border-radius: 0px 0px 15px 15px;
  display: none;
  .menu_icon {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  .menu_close {
    position: absolute;
    top: 13px;
    right: 22px;
  }
  @media (max-width: 767px) {
    display: flex;
    margin-bottom: 30px;
    justify-content: space-between;
    transition: all 0.5s;
  }
  .mobile-sidebar {
    position: relative;
    width: 238px;
    @media (max-width: 767px) {
      position: fixed;
      left: 0;
      top: 0;
      display: none;
    }
  }
  .show {
    display: block;
  }
  & > .logo {
    img {
      height: 25px;
    }
  }
}



.dactive {
  color: #ffff !important;
  color: rgb(2, 2, 2) !important;
}

// .active {
//     background-color: var(--colors-orange);
//     border-radius: 10px;
// }

// .dactive {
//     color: #ffff!important;
//     color: rgb(2, 2, 2)!important;
// }
